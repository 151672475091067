import type { UserConsentManagementModel } from "@cm-websites/common";

export const GROUPS: UserConsentManagementModel.Group[] = [
  {
    id: "essential",
    description:
      "Les cookies et traitements de données essentiels permettent des fonctions de base et sont nécessaires au bon fonctionnement du site web.",
    name: "Essentiel",
    required: true,
  },
  {
    id: "analytics",
    description:
      "Les cookies statistiques et les traitements de données collectent des informations de manière anonyme. Ces informations nous aident, en tant qu'exploitant, à comprendre comment les visiteurs utilisent nos offres.",
    name: "Statistiques",
    required: false,
  },
  {
    id: "external-media",
    description:
      "Les contenus des plateformes vidéo et des médias sociaux sont bloqués par défaut. Si les cookies des médias externes sont acceptés, l'accès à ces contenus ne nécessite plus d'autorisation manuelle.",
    name: "Médias externes",
    required: false,
  },
];

export const PROCESSES: UserConsentManagementModel.Process[] = [
  {
    group: "essential",
    id: "consent",
    name: "Paramètres de confidentialité",
    privacyPolicyUrl: "/politique-de-confidentialite",
    provider: "culinario-mortale.fr",
    purpose: "Enregistre les paramètres de confidentialité des visiteurs.",
    required: true,
  },
  {
    group: "analytics",
    id: "google-analytics",
    name: "Google Analytics",
    privacyPolicyUrl: "https://policies.google.com/privacy",
    provider: "Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
    purpose:
      "Cookie de Google pour l'analyse du site web. Il génère des données statistiques sur la manière dont le visiteur utilise le site web.",
    required: false,
  },
  {
    group: "external-media",
    id: "youtube",
    name: "YouTube",
    provider: "Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland",
    purpose: "Utilisé pour déverrouiller le contenu de YouTube.",
    privacyPolicyUrl: "https://policies.google.com/privacy",
    required: false,
  },
];
